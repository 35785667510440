@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import './base.css';
  @import './fonts.css';
}

@layer components {
  @import './buttons.css';
  @import './inputs.css';
}

@layer utilities {
  @import './utils.css';
  @import './rewrites.css';
}
