/**
 * Chrome has a bug with transitions on load since 2012!
 *
 * To prevent a "pop" of content, you have to disable all transitions until
 * the page is done loading.
 *
 * https://lab.laukstein.com/bug/input
 * https://twitter.com/timer150/status/1345217126680899584
 */

html {
  line-height: 1.7 !important;
}

body.loading::before {
  content: '';
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: white;
}

body.loading * {
  transition: none !important;
}

::-moz-placeholder {
  opacity: 1;
}

a {
  @apply no-underline;
}

[placeholder] {
  text-overflow: ellipsis;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
