@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/NunitoSans-Light.WOFF') format('woff');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/NunitoSans-Regular.WOFF') format('woff');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('/fonts/NunitoSans-SemiBold.WOFF') format('woff');
}

@font-face {
  font-family: 'Nunito Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/NunitoSans-Bold.WOFF') format('woff');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/Unbounded-Medium.WOFF') format('woff');
}

@font-face {
  font-family: 'Unbounded';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/Unbounded-Bold.WOFF') format('woff');
}
