.form-input,
.form-textarea {
  @apply placeholder-bodyGrey bg-lightGrey text-dark focus:outline-none;
}

.form-disabled,
.form-input:disabled,
.form-textarea:disabled {
  @apply cursor-not-allowed bg-lightGrey text-bodyGrey shadow-none focus:ring-0;
  -webkit-text-fill-color: #d6d6d6;
  opacity: 1;
}

.form-error {
  @apply !border-accent ring ring-accent ring-opacity-50;
}
